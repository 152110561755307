import type {
  BuildTypeId,
  BuildTypeInternalId,
  Fetchable,
  ProjectId,
  ProjectInternalId,
} from '../../types'
import type {KeyValue, WritableKeyValue} from '../../utils/object'
import type {ErrorAnswerType} from '../CleanupBuildType/Cleanup.types'

export type HolderType = 'project' | 'buildType' | 'template'

/** POLICY */
export type PolicyTemplateType = {
  name: string
  fullName: string
  projectId: ProjectInternalId
  externalId: BuildTypeId
}
export type PolicyTemplatesType = readonly PolicyTemplateType[]
type BuildTypeTemplatesType = {
  basedOnInaccessibleTemplates: boolean
  templates: PolicyTemplatesType
}
type PolicyEntryMeta = {
  internalId: ProjectInternalId | BuildTypeInternalId
  externalId: ProjectId | BuildTypeId
  name: string
  everythingPolicy: boolean | null | undefined
  buildTypeTemplates?: BuildTypeTemplatesType | null | undefined
}
export type PolicyHolderType =
  | {
      readonly holderId: ProjectInternalId
      readonly holderType: 'project'
    }
  | {
      readonly holderId: BuildTypeInternalId
      readonly holderType: 'buildType'
    }
type PolicyEntryOptions = {
  ownPrevent: boolean
  preventDependenciesArtifactsFromCleanup: boolean
  cleanupPoliciesDisabled: boolean
  ownDisabled: boolean
}
export type PolicyType = {
  ownPolicy: boolean
  levelDescription: 'Artifacts' | 'History' | 'Everything'
  policyDescription: string
  policyParameters: {
    'keepDays.count'?: string
    'keepBuilds.count'?: string
    artifactPatterns?: string
  }
}
export type PolicyEntry = PolicyEntryMeta & {
  artifactsPolicy: PolicyType | null | undefined
  historyPolicy: PolicyType | null | undefined
  everythingPolicy: PolicyType | null | undefined
  options: PolicyEntryOptions
}
export type Settings = {
  daysCount: number | null | undefined
  buildsCount: number | null | undefined
  artifactPatterns: string | null | undefined
}
export type UpdatePolicyPayload = {
  holder: HolderType
  holderId: string
  everythingSettings: Settings | null | undefined
  historySettings: Settings | null | undefined
  artifactsSettings: Settings | null | undefined
  preventDependencies: boolean | null | undefined
}
export type DiskUsageType = {
  buildType: WritableKeyValue<string, number>
  project: WritableKeyValue<string, number>
}
export type DiskUsageNode = Element & {
  nodeName: keyof DiskUsageType
}
export const getEmptyDiskUsage = (): DiskUsageType => ({
  buildType: {},
  project: {},
})

/** Action Creators Props */
export type ACPGetDiskUsagePropsType = ProjectId

/** REST **/
export type Policy = {
  projectInternalId: ProjectInternalId
  projectExternalId: ProjectId
  projectDescription: string
  editPermitted: boolean
  projectPolicies: PolicyEntry
  templatesPolicies: ReadonlyArray<PolicyEntry>
  buildTypesPolicies: ReadonlyArray<PolicyEntry>
}
export type PoliciesResponse = {
  policiesPerProject: ReadonlyArray<Policy>
}
export type CleanupPoliciesRequestActions = string
export type LastActionType = {
  action: CleanupPoliciesRequestActions | null
  loading: boolean
  ready: boolean
}
export type CleanupPoliciesStateType = {
  entities: KeyValue<ProjectInternalId, PoliciesResponse>
  errors: ReadonlyArray<ErrorAnswerType>
  requestStatus: Fetchable<CleanupPoliciesRequestActions | null>
  diskUsage: DiskUsageType
}
export type OwnerType = {
  internalId: ProjectInternalId
  externalId: ProjectId
}
export type EntityType = {
  policy: PolicyEntry
  type: HolderType
  owner: OwnerType
}
export type OwnProps = {
  readonly projectInternalId: ProjectInternalId
  readonly projectId: ProjectId
}
export type StateProps = {
  readonly policiesAreLoaded: boolean
  readonly lastAction: LastActionType
  readonly policies: PoliciesResponse | null | undefined
}
export type ActionProps = {
  readonly fetchProjectPolicies: (
    arg0: ProjectInternalId,
    arg1: {
      includeSubprojects: boolean
    },
  ) => unknown
  readonly fetchDiskUsage: (arg0: ACPGetDiskUsagePropsType) => unknown
  readonly resetPolicy: (arg0: PolicyHolderType) => unknown
  readonly editPolicy: (arg0: UpdatePolicyPayload) => unknown
  readonly disablePolicy: (arg0: PolicyHolderType, arg1: boolean | null | undefined) => unknown
}
export type HooksProps = {
  readonly showSubprojects: boolean
  readonly toggleSubprojects: (arg0: string) => unknown
}
type HandlersProps = {
  readonly fetchPolicies: () => unknown
}
export type Props = OwnProps & StateProps & ActionProps & HooksProps & HandlersProps
